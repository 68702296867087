@import '../core/variables';
@import '../mixins/breakpoints.scss'; //// override defaults
//md-content {
//  background-color: transparent;
//}
//
//// man kan ju för fan inte se den annars
//md-divider {
//  border-top-color: rgba(0, 0, 0, 0.62);
//}
//
//md-toolbar,
//md-toolbar.md-table-toolbar.md-default-theme:not(.md-menu-toolbar).md-default,
//md-toolbar.md-table-toolbar:not(.md-menu-toolbar).md-default {
//  background-color: theme-color('background', 'white') !important;
//  color: theme-color('text', 'black') !important;
//}
//
///**
//* MD-tooltipinside facilities using this //*/
.md-tooltip {
  font-size: 14px;
  height: auto;
}

// According to https://stackoverflow.com/a/43300685
md-toast.md-center {
  position: fixed !important;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 0px;
}

md-toast {
  position: fixed;
  bottom: 10px;
}

@include breakpoints-below(lg) {
  md-dialog {
    max-height: 100%;
  }
}
