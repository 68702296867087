@import './colors';

$default-box-shadow:
  0 1px 3px 0 rgba(0, 0, 0, 0.2),
  0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.12);
$default-border-radius: 10px;

$color-red: theme-color('error', 'base');
$color-green: theme-color('primary', 'base');
$color-blue: theme-color('info', 'base');
$color-orange: theme-color('warn', 'base');
$primary-color: theme-color('primary');

/*
#939393
#7e7e7e
#696969
#545454
#3f3f3f
#2a2a2a
#151515
#000000

*/

// Easing Curves
// loaned from angularjs-material
// read more about ease|linear etc at: https://www.w3schools.com/cssref/css3_pr_transition-timing-function.asp
//--------------------------------------------
$swift-ease-out-duration: 0.4s !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 0.3s !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 0.5s !default;
$swift-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1) !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 0.08s !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;

$material-enter-duration: 0.3s;
$material-enter-timing-function: cubic-bezier(0, 0, 0.2, 1);
$material-enter: all $material-enter-duration $material-enter-timing-function;

$material-leave-duration: 0.3s;
$material-leave-timing-function: cubic-bezier(0.4, 0, 1, 1);
$material-leave: all $material-leave-duration $material-leave-timing-function;
