@import '../../../scss/core/colors.scss';
@import '../../../scss/core/variables.scss';

powershare-dashboard-component {
  .cluster-block {
    height: auto;
    padding: 5px;
    width: 332px;

    & > div.layout-row {
      height: 48px;
    }

    h3 {
      color: theme-color('gray', '300');
      margin: 10px 5px 10px 5px;
      font-size: 16px;
      text-transform: uppercase;
    }

    .cluster-block-child {
      height: 50em !important;
      border-radius: $default-border-radius;
      background-color: theme-color('white');
      overflow: auto;

      h2,
      h3 {
        margin: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    .cluster-block {
      height: auto;
      width: 50%;
      max-width: 50%;
      min-width: 50%;

      .cluster-block-child {
        //height: auto;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
  @media (max-width: 992px) {
    .cluster-block {
      height: auto;
      width: 100%;
      max-width: 100%;
      min-width: 100%;

      .cluster-block-child {
        //  height: auto;
        width: 100%;

        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}
