.system-config-v2_form {
  .simplified-mode {
    .active {
    }
  }

  md-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
