@import '../core/colors';

.ferro-input {
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;

  label {
    font-size: 16px;
    margin: 0 0 5px 15px;
  }
}

md-input-container {
  /* NOTE: Check the demo's HTML to see some additional RTL support CSS */
  /* Setup animations similar to the ng-messages */
  margin: 5px 0 18px 0;

  .hint {
    /* Position the hint */
    position: absolute;
    left: 2px;
    top: 40px;
    right: auto;
    bottom: 7px;
    /* Copy styles from ng-messages */
    font-size: 12px;
    line-height: 14px;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    /* Set our own color */
    color: theme-color('gray', 'base');

    &.ng-hide,
    &.ng-enter {
      bottom: 26px;
      opacity: 0;
    }

    &.ng-leave {
      bottom: 7px;
      opacity: 1;

      &.ng-leave-active {
        bottom: 26px;
        opacity: 0;
      }
    }

    &.ng-enter.ng-enter-active {
      bottom: 7px;
      opacity: 1;
    }
  }

  .explanation {
    color: theme-color('gray', 'base');
  }
}
