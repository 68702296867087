/**
* NOTE Custom scrollbars
*/

body {
  scrollbar-face-color: #555;
  scrollbar-track-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 3px;
  height: 2px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
