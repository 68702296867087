md-progress-linear {
  max-width: 100%;
}

.animate-if {
  background: white;
  border: 1px solid black;
  padding: 10px;
  &.ng-enter,
  &.ng-leave {
    transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  }
  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: 0;
  }
  &.ng-enter.ng-enter-active,
  &.ng-leave {
    opacity: 1;
  }
}

.slideupanddownbatterysim {
  transition: 1s linear all;
  max-height: 1000px;
  overflow: hidden;
  &.ng-hide {
    max-height: 0;
  }
}

.slidupanddown {
  transition: 0.5s linear all;
  max-height: 700px;
  overflow: hidden;
  &.ng-hide {
    max-height: 0;
  }
}

.ferro-sidebar-dropdownmenu {
  transition: 0.5s linear all;
  max-height: 300px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  color: white;
  li {
    color: white;
    text-align: left;
    a {
      color: white;
    }
  }
  &.ng-hide {
    max-height: 0;
  }
}

.arrow-trans {
  /* Firefox */
  -moz-transition: all 0.5s ease;
  /* WebKit */
  -webkit-transition: all 0.5s ease;
  /* Opera */
  -o-transition: all 0.5s ease;
  /* Standard */
  transition: all 0.5s ease;
}

.arrow-rotate {
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}
