@import '../../../scss';

.ferro-box {
  display: inline-block;
  float: left;
  margin-top: 30px;
  background-color: theme-color('white');
  color: theme-color('black');
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
}

view-box {
  md-content {
    border-radius: $default-border-radius;
  }

  md-toolbar,
  .md-toolbar-tools {
    border-radius: $default-border-radius;
  }

  view-box-content {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  md-content {
    display: inline-block;
  }

  .graph-container {
    width: 100%;
    height: 550px;
    min-height: 550px !important;
    display: inline-block;
  }

  @include breakpoints-below(md) {
    .graph-container {
      height: 350px;
      margin: 5px 0 5px 0;
      padding: 0 5px 0 5px;
    }
  }

  @include breakpoints-between(md, lg) {
    .graph-container {
      height: 450px;
    }
  }
}
