@import '../../../scss';
@import '../../components/dashboard-block/dashboard-block.component';

#aceSchematicId {
  @include breakpoints-below('sm') {
    overflow-x: scroll;
  }
}

.evse-overview,
.facility-information,
.dashboard-energy-summeries,
.dashboard-weather-information {
  min-width: 350px;

  img {
    width: 25%;
    margin-top: 5px;
  }

  .md-subheader {
    background: theme-color('background', 'base');
    font-weight: bold;
    font-size: 14px;

    span {
      float: right;
    }
  }

  md-list {
    span {
      float: right;
    }
  }

  * md-list-item {
    margin-top: 15px;
    margin-bottom: 15px;
    color: theme-color('black', 'base') !important;

    md-icon {
      margin-right: 18px !important;
    }
  }

  * p {
    span:nth-child(1) {
      font-weight: 700;
    }

    span:nth-child(2) {
      float: right;
    }
  }
}

@include breakpoints-below(xs) {
  system-dashboard-information,
  .facility-information,
  .dashboard-energy-summeries,
  .dashboard-weather-information {
    width: 100%;
    min-width: 100%;
  }
}

.dashboard-energy-summeries {
  md-list-item {
    height: 64px;
  }
}

system-dashboard-component {
  .layout-options {
    position: absolute;
    right: 35px;
    width: auto;
    left: 280px;
    margin-top: 0;
    z-index: 49;
  }

  @include breakpoints-below(lg) {
    .layout-options {
      right: 15px;
      left: 15px;
    }
  }

  .dashboard-header {
    margin-bottom: 0;
    border-radius: $default-border-radius;

    md-toolbar,
    .md-toolbar-tools,
    .ferro-header {
      border-radius: $default-border-radius;
    }
  }

  .chart {
    width: 100%;
    height: 100%;
  }

  .maps-block {
    width: 500px;

    #mapSystemLocation {
      height: 100%;
      border-radius: $BORDER_RADIUS_DASHBOARD_BLOCK;
      overflow: hidden;
      z-index: 40;
    }

    .aceschematic-parent {
      overflow-x: auto;
    }

    #aceSchematicId {
      width: 100%;
      max-width: 100%;

      overflow-x: auto;

      svg {
        width: 42em;
        height: 31em;
      }
    }

    .bat-and-pv-graphs {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: 500px) {
      .dashboard-energy-summeries {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

#powerSchematicId {
  svg {
    max-width: 100%;
  }
}
