/*
This is necessary due to when scrolling when there is a dialog open the dialog might be stuck in the top and
cause unwanted behaviour.
 */
.md-scroll-mask {
  position: fixed !important;
}

.md-dialog-container {
  position: fixed !important;
}
