system-dashboard-chart-box {
  width: 100%;

  .md-nav-bar {
    overflow-x: auto;
    overflow-y: hidden;
  }

  #energy-charts {
    overflow: unset !important;

    .chart {
      height: 430px;
    }

    md-content {
      overflow: unset;
    }
  }
}
