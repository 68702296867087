.add-subscription-dialog {
  min-width: 600px;

  .input {
    flex-grow: 1;
  }

  .submit {
    flex-grow: 0;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
