#live-data-component {
  .out-graph-container {
    height: 350px !important;
  }

  .graph-container {
    height: 350px !important;
    min-height: 350px !important;
    max-height: 350px !important;
  }
}
