@import '../../../../scss/mixins/breakpoints.scss';

system-information-component {
  view-box {
    max-width: 100% !important;

    @include breakpoints-above('sm') {
      margin-right: 16px;
    }

    .boxes {
      margin: 10px 10px 0 0;
      max-width: 100% !important;
      width: 450px;

      md-content {
        @include breakpoints-above('sm') {
          height: 500px;
        }
      }
    }
  }
}
