#spot-price-feedback {
  .spot-price-feedback-comment {
    field-sizing: content;
  }

  .spot-price-rating {
    *.filled {
      color: orange;
    }
  }
  .spot-price-feedback-content {
    padding: 16px;
    p {
      margin-top: 0px;
    }
  }
}
