@import '../../../scss/index';

.ferro-view-control-sidenav {
  top: 50px;
  position: fixed !important;
  width: 250px;

  md-toolbar {
    background-color: theme-color('backgroundNavigation');
    color: theme-color('white');

    .md-toolbar-tools {
      color: theme-color('white');
      background-color: theme-color('backgroundNavigation');
    }
  }

  md-divider {
    margin: 20px 0;
  }

  md-input-container {
    width: 100%;
  }

  md-backdrop {
    position: fixed;
  }

  md-content {
    color: theme-color('black');
    padding: 10px;
    overflow-y: auto;
    height: 100%;
  }

  md-radio-button {
    margin-bottom: 5px !important;
    margin-right: 0 !important;
    width: 100px;
  }
}

@media screen and (min-width: 1280px) {
  .ferro-view-control-sidenav,
  .ferro-embd-right-sidenav {
    margin-left: auto !important;
    right: 0 !important;
    -webkit-transition: position 2s;
    transition: position 2s;
  }
}
