@import '../../../scss';

#header {
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  position: fixed;
  min-width: 100%;
  background-color: theme-color('backgroundNavigation');
  z-index: get-z-index('header');

  a,
  button {
    text-transform: uppercase;
    color: theme-color('white');
    background-color: theme-color('backgroundNavigation');
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 700;

    &:hover {
      color: theme-color('white', '700');
    }

    md-icon {
      color: theme-color('white') !important;
    }
  }

  .logo {
    width: auto;
    height: 25px;
    max-height: 25px;
    min-height: 25px;
    margin: auto auto auto 45px;
  }
}

.header-sidenav {
  z-index: 1001;
  background-color: theme-color('backgroundNavigation');
  position: fixed;

  md-content {
    background-color: theme-color('backgroundNavigation');
  }

  .help-button {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    float: right;
    border-radius: 30px 8px 30px 30px;
    background-color: rgb(111, 182, 65);
    box-shadow: none;
    color: rgb(255, 255, 255);
    z-index: 2147483647;
    cursor: pointer;
    padding: 8px;
    transition: all 0.1s ease-out 0s;
    //  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;

    md-icon {
      margin-left: 1px;
      margin-right: 15px;
    }
  }

  md-content {
    text-transform: uppercase;
    width: 100%;

    hr {
      width: 100%;
      border: 1px solid #2c2c2c;
    }

    a,
    h2 {
      margin: 10px;

      color: theme-color('white');
      background-color: theme-color('backgroundNavigation');
      font-size: 16px;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        color: theme-color('white', '700');
      }

      md-icon {
        color: theme-color('white') !important;
      }
    }
  }
}
