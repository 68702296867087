@import '../core/variables';
@import '../core/colors';
@import '../mixins/breakpoints';

// xs,sm,md.lg
$layout-widths: (
  'mw100': (
    'width': 100%,
    'xs': 100%,
    'sm': 100%,
    'md': 100%,
    'lg': 100%
  ),
  'mw75': (
    'width': 73%,
    'lg': 73%,
    'md': 73%,
    'sm': 100%,
    'xs': 100%
  ),
  'mw50': (
    'width': 48%,
    'lg': 48%,
    'md': 48%,
    'xs': 100%,
    'sm': 100%
  ),
  'mw30': (
    'width': 31%,
    'lg': 31%,
    'md': 31%,
    'sm': 48%,
    'xs': 100%
  ),
  'mw25': (
    'width': 23%,
    'lg': 23%,
    'md': 31%,
    'sm': 48%,
    'xs': 48%
  ),
  'mw10': (
    'width': 8%,
    'lg': 8%,
    'md': 13%,
    'sm': 23%,
    'xs': 48%
  )
);

@mixin ferro-widths($width) {
  @if $width == 100% {
    width: $width;
    max-width: $width;
    min-width: $width;
    vertical-align: top;
  } @else {
    display: inline-block;
    width: $width;
    max-width: $width;
    min-width: $width;
    margin-right: 2%;
    vertical-align: top;
  }
}

@each $key, $value in $layout-widths {
  @each $breakpoint, $width in $value {
    @if $breakpoint == 'width' {
      .#{$key} {
        @include ferro-widths($width);
      }
    } @else {
      @include breakpoints-below($breakpoint) {
        .#{$key} {
          @include ferro-widths($width);
        }
      }
    }
  }
}

/*

  xs: 576px,
        sm: 768px,
        md: 992px,
        lg: 1200px
 */
$ferro-container: (
  'xs': (
    'margin': unset
  ),
  'sm': (
    'margin': unset
  ),
  'md': (
    'margin': unset
  ),
  'lg': ()
);

.ferro-container {
  width: auto;
  padding: 0 15px 0 15px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;

  &__right-content {
    margin-right: unset;
  }
}

.fcontchild {
  min-height: 100%;
  padding: 15px;
}

.ferro-cont-right {
  margin-right: unset;
}

@include breakpoints-above(lg) {
  .ferro-container {
    margin-left: 265px;
    margin-right: 20px;

    &__right-content {
      margin-right: 250px;
    }
  }
  .ferro-cont-right {
    margin-right: 250px;
  }
}

@include breakpoints-below(lg) {
  .ferro-container {
    margin: unset;
  }

  .fcontchild {
    padding: 0px;
  }
}
