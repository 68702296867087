@import '../core/colors';

.fe-success {
  color: theme-color('success', 'base');
}

.fe-info {
  color: theme-color('accent', '300');
}

.fe-error {
  color: theme-color('error', 'base');
}

.fe-warn {
  color: theme-color('warn', 'base');
}

.fe-primary {
  color: theme-color('primary', 'base');
}

.fe-accent {
  color: theme-color('accent', 'base');
}

a {
  color: theme-color('primary', 'base');
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;

  &:focus,
  &:hover {
    color: theme-color('primary', '700');
  }

  &:visited {
    color: theme-color('primary', '300');
  }

  &:-webkit-any-link {
    text-decoration: none;
    cursor: pointer;
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  color: theme-color('black');
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}
