@import '../../../scss';
//
//dashboard-block {
//  width: 100%;
//  height: 100%;
//  display: inline-block;;
//}
$BORDER_RADIUS_DASHBOARD_BLOCK: 10px;

dashboard-block {
  height: auto;
  display: inline-block;
  padding-right: 20px;

  md-content {
    border-radius: $BORDER_RADIUS_DASHBOARD_BLOCK;
  }

  .save-image-btn {
    margin: 0;
  }

  h3 {
    color: theme-color('gray', '200');
    margin: 10px 5px 10px 5px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .dashboard-block-child {
    border-radius: $BORDER_RADIUS_DASHBOARD_BLOCK;
    height: 37em;
    background-color: theme-color('white');

    ng-transclude {
      height: 100%;
      border-radius: $BORDER_RADIUS_DASHBOARD_BLOCK;
    }

    h2,
    h3 {
      margin: 0;
    }
  }

  * button {
    line-height: 0;
    min-height: 15px;
  }
}

//
//@media (max-width: 1024px) {
//
//  dashboard-block,
//  .dashboard-block {
//    height: auto;
//    width: 50%;
//    max-width: 50%;
//    min-width: 50%;
//
//    .dashboard-block-child {
//      //height: auto;
//      width: 100%;
//      max-width: 100%;
//      min-width: 100%;
//    }
//  }
//}

@include breakpoints-below(xs) {
  dashboard-block,
  .dashboard-block {
    padding-right: 0;
    height: auto;
    width: 100%;
    max-width: 100%;
    min-width: 100%;

    .dashboard-block-child {
      //  height: auto;
      width: 100%;

      max-width: 100%;
      min-width: 100%;
    }
  }
}
