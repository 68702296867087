@import '../../../scss/core/colors.scss';
.toast-error-message .md-toast-content {
  font-weight: 700;
  color: theme-color('error') !important;
  text-align: center;
}

.toast-success-message .md-toast-content {
  font-weight: 700;
  color: theme-color('primary') !important;
  text-align: center;
}

.toast-info-message .md-toast-content {
  font-weight: 700;
  color: theme-color('info') !important;
  text-align: center;
}
