@import '../../../../../scss';

.spot-prices-options {
  .view-box-actions {
    height: unset !important;
    .md-toolbar-tools {
      height: unset;
      max-height: unset;
      button {
        margin: 5px;
      }
    }
  }

  .spotprice-status {
    padding: 40px;
    border-radius: 10px;

    h1 {
      font-size: 20px;
      margin-bottom: 2px;
      margin-top: 2px;
    }
    h3,
    p {
      font-weight: 400;
      margin: 1px 0px;
    }

    .spotpris-last-run {
      margin-bottom: 15px;
    }

    * {
      font-weight: 400;
    }
  }

  .spot-price-inputs {
    width: 400px;
    margin: 0 20px;
  }

  .spot-price-failed {
    background-color: rgba(255, 0, 0, 0.1);
  }
  .spot-price-calculated {
    background-color: rgba(127, 194, 84, 0.3);
  }
  .spot-price-prife-diff-to-low {
    background-color: rgba(100, 99, 99, 0.3);
  }
}

@include breakpoints-below(sm) {
  .spot-prices-options {
    .spotprice-status {
      padding: 20px;
    }

    .spot-price-experimental {
      font-size: 8px;
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }
}
