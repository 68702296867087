.incident-alert-container {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
  padding: 10px;
  margin-bottom: 20px;

  @media screen and (max-width: 992px) {
    margin-top: 40px;
  }

  p {
    margin: 0;
  }

  a {
    color: #533f03;
  }

  a:hover {
    text-decoration: underline;
  }
}
