@import '../../../../scss/core/colors.scss';
evse-status-component {
  .evse-status {
    padding: 5px;
    border-radius: 20px;
    width: 50%;
    margin: auto;
  }

  .offline {
    border: 3px solid theme-color('error');
    h3 {
      color: theme-color('error') !important;
    }
  }

  .online {
    border: 3px solid theme-color('primary', '300');

    h3 {
      color: theme-color('primary', '300') !important;
    }
  }

  .charging {
    border: 3px solid theme-color('primary');

    h3 {
      color: theme-color('primary') !important;
    }
  }
}
