@import '../../../scss/core/colors.scss';
.beta-logo {
  position: absolute;
  top: -12px;
  font-size: 12px;
  right: -10px;
  background-color: theme-color('primary');
  //border: 1px solid black;
  border-radius: 25px;
  color: white;
  padding: 5px;
}
