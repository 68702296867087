@import '../../scss/core/color-map';
@import '../../scss/animations/dashboard-animations.scss';
@import '../../scss/animations/animations.scss';
@import '../../scss/mixins/effects.scss';
@import '../../scss/mixins/breakpoints.scss';
@import '../../scss/core/colors.scss';
@import '../../scss/core/fonts.scss';
.ferroamp-svg-schematic {
  .flow-path {
    fill: none;
    fill-rule: evenodd;
    stroke-width: 4;
    stroke-linecap: butt;
    stroke-linejoin: round;
    stroke-miterlimit: 4;
    stroke-opacity: 1;
  }

  .circle {
    fill: theme-color('white');
    fill-opacity: 1;
    stroke-width: 2;
    stroke-miterlimit: 4;
    stroke-opacity: 1;
    paint-order: stroke markers fill;
  }

  .battery-color {
    @include strokeStopColor(system-component-color('battery'));
  }

  .battery-box {
    fill: system-component-color('battery');
  }

  .pv-color {
    @include strokeStopColor(system-component-color('pv'));
  }

  .grid-color {
    @include strokeStopColor(system-component-color('grid'));
  }

  .consumption-color {
    @include strokeStopColor(system-component-color('consumption'));
  }

  .dc-bridge {
    stroke: system-component-color('dc-grid');
  }

  .phase1 {
    fill: system-component-color('l1');
  }

  .phase2 {
    fill: system-component-color('l2');
  }

  .phase3 {
    fill: system-component-color('l3');
  }

  .neutral-arrow {
    fill: system-component-color('neutral');
  }

  .neutral-stroke {
    fill: none;
    stroke: system-component-color('neutral');
    stroke-width: 1px;
  }

  .schematic {
    &_text {
      text-anchor: middle;
      font-size: 16px;
      //      will-change: content;
      font-family: $font_familiy !important;
      &_start {
        @extend .schematic_text;
        text-anchor: start;
      }
    }

    &_power-text {
      text-anchor: middle;
      font-family: $font_familiy !important;
      font-size: 18px !important;
      //      will-change: content;
    }

    &_explain-text {
      font-size: 12px;
      font-family: $font_familiy !important;
      text-anchor: middle;
    }

    &_title-text {
      font-size: 14px;
      font-family: $font_familiy !important;
      text-anchor: middle;
    }

    &_battery-info {
      text-anchor: middle;
      font-family: 'PT Sans', sans-serif !important;
      fill: gray;
      font-size: 12px;
    }
  }

  .connector-rectangle {
    fill: theme-color('accent');
  }

  @include keyframes(consDot) {
    @include dotSecondayAnimation(
      system-component-color('ac-grid'),
      system-component-color('consumption'),
      50px,
      0
    );
  }
  @include keyframes(consExpDot) {
    @include dotFirslyAnimation(
      system-component-color('consumption'),
      system-component-color('ac-grid'),
      -50px,
      0
    );
  }
  @include keyframes(exportGridDot) {
    @include dotSecondayAnimation(
      system-component-color('ac-grid'),
      system-component-color('grid'),
      -50px,
      0
    );
  }
  @include keyframes(importGridDot) {
    @include dotFirslyAnimation(system-component-color('grid'), system-component-color('ac-grid'), 50px, 0);
  }
  @include keyframes(pvDotProd) {
    @include dotFirslyAnimation(system-component-color('pv'), system-component-color('dc-grid'), 50px, 0);
  }
  @include keyframes(dcBridgeOut) {
    @include dotFirslyAnimation(
      system-component-color('dc-grid'),
      system-component-color('dc-grid'),
      0,
      75px
    );
  }
  @include keyframes(dcBridgeIn) {
    @include dotFirslyAnimation(
      system-component-color('dc-grid'),
      system-component-color('dc-grid'),
      0,
      -75px
    );
  }

  @include keyframes(batteryDotCharge) {
    @include dotSecondayAnimation(
      system-component-color('dc-grid'),
      system-component-color('battery'),
      50px,
      0
    );
  }
  @include keyframes(batteryDotDischarge) {
    @include dotFirslyAnimation(
      system-component-color('battery'),
      system-component-color('dc-grid'),
      -50px,
      0
    );
  }
  @include keyframes(ehubAcInDot) {
    @include dotSecondayAnimation(
      system-component-color('ac-grid'),
      system-component-color('black'),
      0,
      -85px
    );
  }
  @include keyframes(ehubAcOutDot) {
    @include dotFirslyAnimation(system-component-color('black'), system-component-color('ac-grid'), 0, 85px);
  }
  @include keyframes(ehubDcInDot) {
    @include dotSecondayAnimation(
      system-component-color('dc-grid'),
      system-component-color('black'),
      0,
      85px
    );
  }
  @include keyframes(ehubDcOutDot) {
    @include dotFirslyAnimation(system-component-color('black'), system-component-color('dc-grid'), 0, -85px);
  }
  @include keyframes(pvClusterDcMicroGridDot) {
    @include dotFirslyAnimation(system-component-color('pv'), system-component-color('dc-grid'), 0, 120px);
  }

  @include keyframes(microgridOut) {
    @include dotSecondayAnimation(
      system-component-color('dc-grid'),
      system-component-color('dc-grid'),
      0,
      -54px
    );
  }
  @include keyframes(microgridIn) {
    @include dotFirslyAnimation(
      system-component-color('dc-grid'),
      system-component-color('dc-grid'),
      0,
      54px
    );
  }

  @include keyframes(clusterBatteryDotCharge) {
    @include dotSecondayAnimation(
      system-component-color('dc-grid'),
      system-component-color('battery'),
      0,
      -120px
    );
  }
  @include keyframes(clusterBatteryDotDischarge) {
    @include dotFirslyAnimation(
      system-component-color('battery'),
      system-component-color('dc-grid'),
      0,
      120px
    );
  }

  /*
.glowing-effect {
DotAnimation(neon2)
}*/

  .dot {
    stroke: none;
    fill-opacity: 1;
    will-change: opacity;

    &_consumption {
      @include DotAnimation(consDot);
    }

    &_consumption-exp {
      @include DotAnimation(consExpDot);
    }

    &_grid-export {
      @include DotAnimation(exportGridDot);
    }

    &_grid-import {
      @include DotAnimation(importGridDot);
    }

    &_pv {
      @include DotAnimation(pvDotProd);
    }

    &_dc-bridge-out {
      @include DotAnimation(dcBridgeOut);
    }

    &_dc-bridge-in {
      @include DotAnimation(dcBridgeIn);
    }

    &_battery-charge {
      @include DotAnimation(batteryDotCharge);
    }

    &_battery-discharge {
      @include DotAnimation(batteryDotDischarge);
    }

    &_ehub-dc-in {
      @include DotAnimation(ehubDcInDot);
    }

    &_ehub-dc-out {
      @include DotAnimation(ehubDcOutDot);
    }

    &_ehub-ac-in {
      @include DotAnimation(ehubAcInDot);
    }

    &_ehub-ac-out {
      @include DotAnimation(ehubAcOutDot);
    }

    &_pv-microgrid {
      @include DotAnimation(pvClusterDcMicroGridDot);
    }

    &_bat-microgrid-charge {
      @include DotAnimation(clusterBatteryDotCharge);
    }

    &_bat-microgrid-discharge {
      @include DotAnimation(clusterBatteryDotDischarge);
    }

    &_microgrid-out {
      @include DotAnimation(microgridOut);
    }

    &_microgrid-in {
      @include DotAnimation(microgridIn);
    }

    &-hidden {
      fill-opacity: 0;
    }
  }

  .hidden {
    display: none;
  }

  .hide-solar-battery {
    stroke: system-component-color('disabled') !important;

    circle {
      stroke: system-component-color('disabled') !important;
    }

    * path {
      fill: system-component-color('disabled') !important;
      stroke: system-component-color('disabled') !important;
    }
  }
}
