@import '../core/colors';

.warning {
  color: theme-color('warn', 'base');
}

.offline {
  color: theme-color('error', 'base');
}

.online {
  color: theme-color('success', 'base');
}
