@import '../../../../../scss/mixins/breakpoints.scss';
/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.calendar-view .md-padding {
  padding: 0;
}
.calendar-view md-content {
  overflow-y: hidden;
  overflow-x: hidden;
}

#scheduling-calendar-parent {
  height: 800px;
  position: relative;
  width: 100%;
  #scheduling-calendar {
    height: 800px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    overflow: hidden;

    .toastui-calendar-week-view-day-names {
      z-index: 20;
      position: relative;
      background-color: white;
    }

    .toastui-calendar-allday {
      height: 22px !important;
      background-color: white;
      z-index: 20;
      position: relative;
    }

    .toastui-calendar-popup-overlay {
      display: none !important;
      visibility: hidden;
    }
  }

  #custom-calendar-popup {
    position: absolute;
    visibility: hidden;
    font-weight: 400;
    padding: 10px;
    min-width: 260px;
    max-width: 400px;
    min-height: 50px;
    max-height: 300px;
    z-index: 10;
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .calendar-popup-content {
      .title {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        overflow-y: hidden;
        font-weight: 800;
        letter-spacing: 0.005em;
      }

      .description {
        padding: 10px;
        max-height: 150px;
        overflow-y: auto;
      }

      .dates,
      .reccurrance {
        font-size: 10px;
        font-style: italic;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .show {
    visibility: visible !important;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
  }
  .hidden {
    -webkit-animation: fadeOut 0.5s;
    animation: fadeOut 0.5s;
  }
}

@include breakpoints-below(lg) {
  #scheduling-calendar {
    overflow-x: auto;

    .tui-full-calendar-week-container {
      width: 500% !important;
    }
  }
}
