.time-picker-container {
  label {
    left: 20px !important;
  }

  .tui-timepicker {
    border: unset !important;
    .tui-timepicker-select {
      border-radius: 5px !important;
    }
    // .tui-timepicker-hour {

    // }
  }
}
