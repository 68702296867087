@import '../../../scss/animations/animations.scss';
@import '../../../scss/core/colors.scss';

#PowerFlowSchematic {
  .disabled {
    stroke: system-component-color('disabled') !important;
  }
  .disabled-text {
    color: system-component-color('disabled') !important;
  }
}

.connector-animation {
  stroke-dasharray: 5 !important;
  stroke-width: 3px !important;
  @include animate(dash 5s infinite linear);
}

.connector-animation-reverse {
  stroke-dasharray: 5 !important;
  stroke-width: 3px !important;
  @include animate(dash-reverse 5s infinite linear);
}

.disabled {
  stroke: system-component-color('disabled') !important;
}

@include keyframes(dash) {
  from {
    -webkit-filter: drop-shadow(
      0 0 5px #fff,
      0 0 10px #fff,
      0 0 15px #e60073,
      0 0 20px #e60073,
      0 0 25px #e60073,
      0 0 30px #e60073,
      0 0 35px #e60073
    );
  }
  to {
    stroke-dashoffset: 100;
    -webkit-filter: drop-shadow(
      0 0 10px #fff,
      0 0 15px #ff9800 0 0 20px #ff9800 0 0 25px #ff9800 0 0 30px #ff9800 0 0 40px #ff9800 0 0 45px #ff4da6
    );
  }
}

@include keyframes(dash-reverse) {
  from {
    -webkit-filter: drop-shadow(
      0 0 5px #fff,
      0 0 10px #fff,
      0 0 15px #e60073,
      0 0 20px #e60073,
      0 0 25px #e60073,
      0 0 30px #e60073,
      0 0 35px #e60073
    );
  }
  to {
    stroke-dashoffset: -100;
    -webkit-filter: drop-shadow(
      0 0 10px #fff,
      0 0 15px #ff9800 0 0 20px #ff9800 0 0 25px #ff9800 0 0 30px #ff9800 0 0 40px #ff9800 0 0 45px #ff4da6
    );
  }
}

.svg-shadow {
  /*-webkit-filter: drop-shadow( 3px 3px 2px #ff5722); */
  -webkit-animation: svg-shadow 1.5s ease-in-out infinite alternate;
  -moz-animation: svg-shadow 1.5s ease-in-out infinite alternate;
  animation: svg-shadow 1.5s ease-in-out infinite alternate;
}

@-webkit-keyframes svg-shadow {
  from {
    -webkit-filter: drop-shadow(
      0 0 5px #fff,
      0 0 10px #fff,
      0 0 15px #e60073,
      0 0 20px #e60073,
      0 0 25px #e60073,
      0 0 30px #e60073,
      0 0 35px #e60073
    );
  }

  to {
    -webkit-filter: drop-shadow(
      0 0 10px #fff,
      0 0 15px #ff9800 0 0 20px #ff9800 0 0 25px #ff9800 0 0 30px #ff9800 0 0 40px #ff9800 0 0 45px #ff4da6
    );
  }
}
