@import '../../scss';

system-list-component {
  .system-list-link {
    display: grid;
    color: theme-color('primary', 'base') !important;
    transition:
      box-shadow $swift-ease-out-duration $swift-ease-out-timing-function,
      background-color $swift-ease-out-duration $swift-ease-out-timing-function;
    cursor: pointer;
    padding: 0 !important;
    height: 48px;
    a {
      display: grid;
      align-items: center;
      padding: 0 24px;
    }
    &:hover {
      background-color: theme-color('white', '700');
      color: theme-color('black') !important;
    }
  }
}
