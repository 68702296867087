img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}
