@import '../../../scss/core/colors.scss';
.alarm-impact {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-flow: row;
  align-items: center;
  border-radius: 5;
  font-weight: 700;

  &-1 {
    background-color: theme-color('warn');
    color: white;
  }

  &-2 {
    background-color: theme-color('error', '300');
    color: white;
  }
  &-3 {
    background-color: theme-color('error');
    color: white;
  }
}
