system-information-comments {
  .comments-list {
    max-height: 500px;
    overflow-y: auto;

    .comment-p {
      margin-top: 5px;

      .the-comment {
        color: theme-color('text', 'black');
        max-height: 200px;
        overflow-y: auto;
      }
    }

    .comment-list-item {
      margin-top: 5px;
      border-radius: 5px;
      padding: 9px;

      .com-title {
        width: 80px;
        min-width: 80px;
        max-width: 80px;
        text-align: start;
      }
    }
  }

  .comment-priority-high {
    border: 1px solid;
    border-color: rgb(255, 191, 0);
    background-color: rgba(255, 224, 133, 0.3);
  }
}
