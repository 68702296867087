@import '../../../scss';

#sidebarview {
  // height: 100%;
  display: block;
  // margin-top: 50px;
  // position: fixed;
  z-index: get-z-index('side-nav-view');
}

#ferro-left-sivenav {
  top: 0;
  height: 100%;
  .md-sidenav-backdrop {
    position: fixed;
    height: 100%;
  }
  .toggle-sidenav-btn {
    position: fixed;
    left: 0;
    top: 5px;
    border-radius: 0 0 5px 0;
    width: 40px;
    max-width: 4px;
    min-width: 40px;
    margin: 0;
    background-color: theme-color('backgroundNavigation');
  }

  * md-icon {
    color: theme-color('white');
    border: none;

    &:focus,
    &:hover {
      border: none;
    }
  }

  .ferro-sidenav {
    position: fixed;

    display: block;
    min-width: 250px !important;
    width: 250px !important;
    max-width: 250px !important;
    background-color: theme-color('black') !important;

    md-content {
      background-color: theme-color('black') !important;
    }
    .logo {
      height: 42px;
      margin: 0 10px;
      /* max-height: 25px; */
      min-height: 25px;
      width: auto;
    }
    .pro-feature-subsection,
    .pro-feature-section {
      position: absolute;
      background-color: theme-color('primary');
      padding: 2px 2px !important;
      border-radius: 2px;
      right: 25px !important;
      font-size: 10px !important;
    }

    .pro-feature-section {
      right: 40px;
      font-size: 12px;
      width: auto !important;
      margin-top: 15px;
    }

    .pro-feature-subsection {
      right: 40px;
      font-size: 12px;
      width: auto !important;
      margin-top: 8px;
    }

    md-content {
      margin-bottom: 100px;
    }

    .logo {
      display: block;
      padding: 10px;

      &:hover {
        background-color: transparent;
      }
    }

    .title {
      font-size: 16px;
      margin-left: 20px;
      color: theme-color('white');
    }

    * {
      color: theme-color('white');

      a {
        background-color: transparent;

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }

    .section-menu {
      list-style: none;
      padding: 0;
      max-width: 100%;
      overflow-x: hidden;
      // Any li components that are active, which means we are in that view ui-sref-active="active"
      .active {
        background-color: theme-color('primary', '700');

        &:after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 0px;
          width: 0px;
          border: 25px solid transparent;
          border-right-color: theme-color('backgroundNavigation');
        }
      }

      .sub-active {
        background-color: theme-color('primary', '700');

        &:after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 0px;
          width: 0px;
          border: 18px solid transparent;
          border-right-color: theme-color('backgroundNavigation');
        }
      }

      .section-link {
        display: flex;
      }

      .section-row {
        position: relative;
        margin: 0;
        border-bottom: 1px solid theme-color('gray', '700');

        &:focus,
        &:hover {
          background-color: theme-color('gray', '900');
        }

        .md-icon {
          margin-right: 10px;
        }

        a {
          text-transform: uppercase;
          font-size: 14px;
          //padding: 0 5px 0 16px;
          text-rendering: optimizeLegibility;
          font-weight: 500;
          padding: 15px;
          width: 100%;
        }
      }

      .subsections-menu {
        padding: 0;
        background-color: theme-color('backgroundNavigation');
        transition: $swift-ease-out;
        max-height: 300px;
        overflow: hidden;

        .ng-hide {
          max-height: 0;
          transition: $swift-ease-in;
        }

        .subsection {
          position: relative;

          a {
            padding: 10px 5px 10px 45px;
            font-size: 12px;
          }

          &:focus,
          &:hover {
            background-color: theme-color('gray', '900');
          }
        }
      }
    }
  }

  *.arrow-trans {
    margin-right: 13px;
    /* Firefox */
    -moz-transition: $swift-linear;
    /* WebKit */
    -webkit-transition: $swift-linear;
    /* Opera */
    -o-transition: $swift-linear;
    /* Standard */
    transition: $swift-linear;
  }

  *.arrow-rotate {
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
}
