@import '../../../scss/index';

.ferro-header {
  z-index: get-z-index('ferro-header');
  min-width: 100%;
  background-color: theme-color('white');
  color: theme-color('black') !important;
  box-shadow: $default-box-shadow;
  border-radius: $default-border-radius;

  .md-toolbar-tools,
  md-toolbar {
    background-color: theme-color('white') !important;
    color: theme-color('black') !important;
    border-radius: $default-border-radius;
  }

  md-input-container {
    margin-bottom: 0;
    margin-top: 0;
  }

  md-content {
    border-radius: $default-border-radius;
  }
}
