@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-ms-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

/**
SCHEMATICs
 */

@mixin animate($args...) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -o-animation: $args;
  animation: $args;
}

@mixin strokeStopColor($color) {
  stroke: $color;
  stop-color: $color;
}

@mixin DotAnimation($animation) {
  -webkit-animation: $animation 2s infinite linear;
  -moz-animation: $animation 2s infinite linear;
  -o-animation: $animation 2s infinite linear;
  animation: $animation 2s infinite linear;
}

@mixin dotFirslyAnimation($color1, $color2, $x, $y) {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    fill: $color1;
  }
  50% {
    transform: translate3d($x, $y, 0);
    opacity: 1;
    fill: $color2;
  }
  51% {
    opacity: 0;
  }
  100% {
    transform: translate3d($x, $y, 0);
    opacity: 0;
  }
}

@mixin dotSecondayAnimation($color1, $color2, $x, $y) {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
    fill: none;
  }
  50% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
    fill: none;
  }
  51% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    fill: $color1;
  }
  100% {
    transform: translate3d($x, $y, 0);
    opacity: 1;
    fill: $color2;
  }
}

@mixin animatedUnderline($color) {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $color;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
