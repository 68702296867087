@import '../../scss';

.system-component {
  .error-things {
    color: white !important;
    border-radius: 10px;
    margin-bottom: 5px;
    padding-bottom: 20px;
    a {
      color: white;
    }
    a:visited {
      color: rgb(175, 175, 175);
    }
    .material-icons {
      color: black !important;
    }
    h2 {
      color: white !important;
    }

    p {
      font-size: 14px;
      margin-left: 54px;
      top: 30px;
      position: absolute;
    }
  }

  .system-offline {
    background-color: theme-color('error');
  }

  .system-ongoing-maintenance {
    background-color: theme-color('warn');
  }
}
