@import '../../../../../scss/mixins/breakpoints.scss';

.ems-config-schedule-picker-parent {
  display: flex;
  align-items: center;
  place-content: center;
  flex-direction: row;
}
.ems-schedule-picker-footer-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  .prev {
    flex: 1 1 0%;
  }
}

.ems-config-schedule-picker {
  flex: 1 1 0%;
  border-radius: 10px;
  margin: 10px;
  margin-bottom: 70px;
  background-color: white;
  min-width: 400px;
  max-width: 500px;
  max-width: 1400px;
  beta-logo {
    div {
      right: -35px !important;
    }
  }
  .ems-config-picker-header {
    border-radius: 10px 10px 0 0;
  }
  .ems-config-schedule-picker-inner {
    padding: 10px;
  }

  md-dialog-content {
    padding: 20px;
  }

  .inner-toolbar {
    background-color: white;
    border-radius: 0;
    border-bottom: 1px solid black;
  }

  input[type='time']::selection {
    background-color: red;
  }

  .days-button {
    button {
      background-color: gray !important;
    }
    .enabled {
      background-color: green !important;
    }
  }

  @include breakpoints-below(lg) {
    .days-button {
      button {
        line-height: 25px !important;
        width: 36px !important;
        height: 25px !important;
      }
    }
  }
}
