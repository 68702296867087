.planned-maintenance-container {
  background-color: rgb(114, 147, 203);
  border-color: rgb(114, 147, 203);
  color: rgb(114, 147, 203);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  @media screen and (max-width: 992px) {
    margin-top: 40px;
  }

  p {
    margin: 0;
  }

  a {
    color: #533f03;
    &:visited {
      color: black;
    }
  }

  a:hover {
    text-decoration: underline;
  }
}
