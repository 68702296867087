@import '../core/colors';
* {
  box-sizing: border-box;
}

body,
html {
  font-family: 'DM Sans', sans-serif;
  background-color: theme-color('background');
  font-size: 14px;
  min-height: 100%;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

body {
  position: initial !important;
  top: initial !important;
  overflow: initial !important;
}

html {
  overflow: initial !important;
}

#mainviews {
  margin-top: 55px;
  margin-bottom: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

#loginpage {
  background-color: theme-color('background');
}

button,
html,
input,
body,
select,
textarea {
  font-family: 'DM Sans', sans-serif;
}
