.system-config-v2 {
  // Is different colors if the icon is inside md-switch or md-input-container. Unify them here.
  md-icon {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .suffix {
    position: relative;
    float: right;
    right: 30px;
    bottom: 25px;
    color: rgba(0, 0, 0, 0.54);
  }

  &_last-update-timestamp {
    font-size: 10px;
    margin-right: 10px;
  }

  &_form {
    .title-row {
      margin-top: 18px;
      text-transform: uppercase;
    }
  }

  md-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
