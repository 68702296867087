@import '../../../scss';

.gauage {
  .dashboard-block-child {
    height: auto;
  }
}

.ferroGauge {
  margin: auto;
  width: 200px;
  height: 12em;

  .chart-filled {
    fill: graph-color('blue', 'line');
  }

  .chart-empty {
    fill: #dedede;
  }

  .chart-filled-pos {
    fill: graph-color('red', 'line');
  }

  .chart-empty-pos {
    fill: #dedede;
  }

  .chart-filled-neg {
    fill: graph-color('green', 'line');
  }

  .chart-empty-neg {
    fill: #dedede;
  }

  .chart-text {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-anchor: middle;
    font-size: 24px;
    font-weight: bold;
    fill-opacity: 1;

    tspan {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }

  .chart-text-unit {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-anchor: middle;
    font-size: 10px;
    font-weight: normal;
    fill-opacity: 1;

    tspan {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }

  .needle,
  .needle-center {
    fill: #464a4f;
  }

  svg {
    font-size: 10px;
  }
}
